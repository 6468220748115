<template>
  <div>
    <!--------------------- loading --------------------->
    <Loading class="text-center loading-container"></Loading>
    <!--------------------- section --------------------->
    <section v-if="!isLoading" key="content" class="section section-secondary jpadding">
      <div class="jcard jcard-main jcard-nohover">
        <!-- TITLE -->
        <div>
          <h3>Appointments List</h3>
        </div>
        <!-- Search -->
        <SearchAndFilters
          class="d-flex mb-3"
          :searchList="appointmentsList"
          :filters="newFilters"
        ></SearchAndFilters>
        <!-- FILTERS -->
        <div class="d-flex mb-3">
          <div>
            <i class="fal fa-filter"></i>
            <button class="btn btn-outline-primary ml-2" @click="filterQuery(0)">
              All
            </button>
            <button class="btn btn-outline-primary ml-2" @click="filterQuery(1)">
              Pending
            </button>
            <button class="btn btn-outline-primary ml-2" @click="filterQuery(2)">
              Approved
            </button>
            <button class="btn btn-outline-primary ml-2" @click="filterQuery(3)">
              Completed
            </button>
            <button class="btn btn-outline-primary ml-2" @click="filterQuery(4)">
              Cancelled
            </button>
          </div>
          <button @click="showCreateAppointmentDialog()" class="btn btn-primary ml-auto" to="create-user">
            <i class="fa fa-plus"></i>
          </button>
        </div>
        <!-- TABLE -->
        <table class="table">
          <thead>
            <tr>
              <th>Id</th>
              <th scope="col">Service</th>
              <th scope="col">Customer</th>
              <th scope="col">Date</th>
              <th scope="col">Status</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(appointment, index) in appointmentsList" :key="appointment.id">
              <td>
                <small>{{ appointment.id.substring(0, 8) }}</small>
              </td>
              <td>{{ servicesList.get(appointment.serviceId).name }}</td>
              <td>{{ usersMap.get(appointment.userId).name }}</td>
              <td>{{ appointment.dateString }}</td>
              <td>
                <b-dropdown right variant="link" toggle-class="text-secondary" class="mx-auto">
                  <template #button-content>
                    <span
                      class="badge text-uppercase"
                      :class="$C.STATUS.APPOINTMENT_STATUS_COLORS[appointment.status]"
                      >{{ $C.STATUS.APPOINTMENT_STATUS_INDEX[appointment.status] }}</span
                    >
                  </template>
                  <b-dropdown-item
                    v-for="(status, i) in $C.STATUS.APPOINTMENT_STATUS_INDEX"
                    :key="i"
                    @click="updateAppointmentStatus(appointment.id, i)"
                    ><span class="badge text-uppercase">{{
                      $C.STATUS.APPOINTMENT_STATUS_INDEX[i]
                    }}</span></b-dropdown-item
                  >
                </b-dropdown>
              </td>
              <td>
                <b-dropdown
                  right
                  variant="link"
                  toggle-class="jbtn jbtn-icon jbtn-icon-link"
                  class="ml-auto"
                  no-caret
                >
                  <template #button-content>
                    <i class="fa fa-ellipsis-v"></i>
                  </template>
                  <b-dropdown-item :to="{ name: 'AppointmentCardAdmin', params: { id: appointment.id } }"
                    ><i class="fal fa-cog mr-2"></i> Manage
                  </b-dropdown-item>
                  <!-- <b-dropdown-item @click="deleteService(index)"><i class="fal fa-trash mr-2"></i> Delete
                    appointment</b-dropdown-item> -->
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <!-- ----- new appointment modal --------------------------------- -->
    <b-modal
      :hide-header="true"
      :hide-footer="true"
      id="create-appointment-modal"
      ref="create-appointment-modal"
      centered
      title="BootstrapVue"
      size="lg"
    >
      <div class="jdialog-top">
        <div class="close-container">
          <span @click="previousStep()" class="mr-auto jclose"><i class="fa fa-arrow-left "></i></span>
          <h3>
            <i class="fal fa-calendar-plus"></i>
            <strong> Create appointment</strong>
          </h3>
          <span @click="$bvModal.hide('create-appointment-modal')" class="ml-auto jclose"
            ><i class="fa fa-times "></i
          ></span>
        </div>
        <p>
          <small>Please follow these steps to create a new appointment.</small>
        </p>
      </div>
      <!--------------------- users list --------------------->
      <div v-if="steps[0]" class="jdialog-main">
        <h5>1. Select an owner:</h5>
        <!-- Search -->
        <SearchAndFilters class="d-flex mb-3" :searchList="usersList"></SearchAndFilters>
        <div class="grid-1">
          <div
            v-for="user in usersList"
            :key="user.id"
            class="jcard-border jcard-border-sm users-list-item"
            @click="nextCreateStep(1, user.id)"
          >
            <div>
              <small>Id</small>
              <p>{{ user.id.substring(0, 8) }}</p>
            </div>
            <div>
              <small>Name</small>
              <p>{{ user.firstName.substring(0, 1) + ". " + user.lastName }}</p>
            </div>
            <div class="ml-auto next">
              <i class="fa fa-chevron-right"></i>
            </div>
          </div>

          <b-alert v-if="showInputError" show variant="danger"
            ><i class="fad fa-exclamation-circle"></i>
            {{ validationMsg }}
          </b-alert>
        </div>
      </div>
      <!--------------------- watches list --------------------->
      <div v-if="steps[1] && !dialogIsLoading" class="jdialog-main">
        <h5>2. Select a watch:</h5>
        <div class="grid-1">
          <div
            v-for="watch in watchesList"
            :key="watch.id"
            class="jcard-border jcard-border-sm users-list-item"
            @click="nextCreateStep(2, watch.id)"
          >
            <div>
              <small>Id</small>
              <p>{{ watch.id.substring(0, 8) }}</p>
            </div>
            <div>
              <small>Name</small>
              <p>{{ watch.model }}</p>
            </div>
            <div class="ml-auto next">
              <i class="fa fa-chevron-right"></i>
            </div>
          </div>

          <b-alert v-if="showInputError" show variant="danger"
            ><i class="fad fa-exclamation-circle"></i>
            {{ validationMsg }}
          </b-alert>
        </div>
      </div>
      <!--------------------- services list --------------------->
      <div v-if="steps[2]" class="jdialog-main">
        <h5>3. Select a service:</h5>
        <div class="grid-1">
          <div
            v-for="service in Array.from(servicesList.values()).filter(serv => serv.modelId === watchModleId)"
            :key="service.id"
            class="jcard-border jcard-border-sm users-list-item"
            @click="nextCreateStep(3, service.id)"
          >
            <div>
              <small>Id</small>
              <p>{{ service.id.substring(0, 8) }}</p>
            </div>
            <div>
              <small>Name</small>
              <p>{{ service.name }}</p>
            </div>
            <div class="ml-auto next">
              <i class="fa fa-chevron-right"></i>
            </div>
          </div>

          <b-alert v-if="showInputError" show variant="danger"
            ><i class="fad fa-exclamation-circle"></i>
            {{ validationMsg }}
          </b-alert>
        </div>
      </div>
      <!--------------------- choose date --------------------->
      <div v-if="steps[3]" class="jdialog-main">
        <h5>4. Set a date:</h5>
        <div class="grid-1">
          <input id="datepicker" type="date" class="form-control" v-model="appointmentDate" />

          <b-alert v-if="showInputError" show variant="danger"
            ><i class="fad fa-exclamation-circle"></i>
            {{ validationMsg }}
          </b-alert>
        </div>
      </div>
      <!--------------------- loading list --------------------->
      <div v-if="dialogIsLoading" key="dialogLoading" class="text-center loading-container">
        <i class="text-blue fad fa-spinner-third fa-spin fa-3x"></i>
        <p class="mt-3">Loading ...</p>
      </div>
      <div class="jdialog-bottom with-cancel">
        <button @click="$bvModal.hide('create-appointment-modal')" class="jbtn jbtn-sm jbtn-red">
          <i class="fa fa-times"></i> Cancel
        </button>
        <button v-if="steps[3]" @click="createAppointment()" class="jbtn jbtn-sm">
          <span v-if="isSaving" key="spinner"><i class="fad fa-spinner-third fa-spin"></i></span>
          <span v-else key="button"><i class="fa fa-save"></i> Submit</span>
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import toast from "@/assets/js/toast";
import Loading from "@/components/general/Loading";
import SearchAndFilters from "@/components/general/SearchAndFilters";
import moment from "moment";
import C from "@/const";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;
const serverTimestamp = firebase.serverTimestamp;

export default {
  name: "AppointmentsList",
  components: {
    Loading,
    SearchAndFilters
  },
  data() {
    return {
      newFilters: [],

      //init
      isLoading: true,
      isEmpty: false,
      dialogIsLoading: true,

      //dialog form

      showInputError: false,
      validationMsg: "",

      //form button
      isSaving: false,

      //lists
      watchesList: [],
      appointmentsList: [],
      usersList: [],
      servicesList: new Map(),
      usersMap: new Map(),

      //edit data
      createdOn: serverTimestamp,
      appointmentDate: moment(new Date()).format("YYYY-MM-DD"),
      userId: "",
      watchId: "",
      watchModleId: "",
      serviceId: "",
      status: 0,

      //flags
      asyncFlags: [false, false, false],
      filters: [false, false, false, false, false],
      activeFilter: 0,
      steps: [true, false, false],
      activeStep: 0
    };
  },
  methods: {
    loadingDone: function(error) {
      this.$root.$emit("loadingDone", error);
      if (!error) {
        this.isLoading = false;
        this.dialogIsLoading = false;
      }
    },
    addToFilters: function(type, title, dbField, selectionArray) {
      console.log(this.newFilters);
      console.log(selectionArray);
      console.log("TYPE:" + type);

      const newFilter = {
        title: title,
        type: type,
        field: dbField,
        selection: selectionArray
      };
      this.newFilters.push(newFilter);
      // this.$set(this.newFilters, position, newFilter);
      console.log("UpdatedFilters;");
      console.log(this.newFilters);
    },
    prepareSearchData: function() {
      if (!this.asyncFlags || !this.asyncFlags[0] || !this.asyncFlags[1] || !this.asyncFlags[2]) return;

      console.log("SETTING UP SEARCH for appointments: ");
      console.log(this.appointmentsList);

      this.appointmentsList.forEach(appointment => {
        const user = this.usersMap.get(appointment.userId);
        const service = this.servicesList.get(appointment.serviceId);
        // breaking the naming conventions since the keys are used as titles for the correspoing filter -> TODO: improve this (might lead to some complications)
        appointment.user = user ? user.firstName.substring(0, 1) + ". " + user.lastName : "-";
        appointment.service = service ? service.name : "-";
        appointment.dateString = moment(C.getDateFromTimestamp(appointment.date)).format("DD-MM-YYYY");
        appointment.statusName = C.STATUS.APPOINTMENT_STATUS_INDEX[appointment.status];
      });
      this.addToFilters(C.FILTER_TYPE.DATE, "Date", "date", null);
      this.addToFilters(
        C.FILTER_TYPE.MULTI_SELECTION,
        "Status",
        "statusName",
        C.STATUS.APPOINTMENT_STATUS_INDEX
      );
    },
    addDocToAppointmentList: function(appointmentDoc) {
      const appointment = appointmentDoc.data();
      appointment.id = appointmentDoc.id;

      this.appointmentsList.push(appointment);
    },
    allAppointments: function() {
      this.isLoading = true;
      db.collection(C.COLLECTION.APPOINTMENTS)
        .where("paid", "==", true)
        .onSnapshot(querySnapshot => {
          this.appointmentsList.splice(0);
          querySnapshot.forEach(doc => {
            this.addDocToAppointmentList(doc);
          });
          this.asyncFlags[0] = true;
          if (this.asyncFlags[1] && this.asyncFlags[2]) {
            this.prepareSearchData();
            this.loadingDone();
          }
        });
      // .catch((error) => {
      //   this.isLoading = false;
      //   console.log("Error getting appointments: ", error);
      // });
    },
    allServices: function() {
      this.isLoading = true;
      db.collection(C.COLLECTION.SERVICE_CATALOG)
        .get()
        .then(querySnapshot => {
          const serviceNames = [];
          querySnapshot.forEach(doc => {
            const service = doc.data();
            service.id = doc.id;
            this.servicesList.set(doc.id, service);
            serviceNames.push(service.name);
          });

          console.log("Services ready to add to filters");
          this.addToFilters(C.FILTER_TYPE.MULTI_SELECTION, "Service", "service", serviceNames);

          this.asyncFlags[1] = true;
          if (this.asyncFlags[1] && this.asyncFlags[2]) {
            this.prepareSearchData();
            this.loadingDone();
          }
        })
        .catch(error => {
          this.loadingDone(error);
          console.log("Error getting services: ", error);
        });
    },
    allUsers: function() {
      //todo: set hasAppoitment in user, to filter users here
      this.isLoading = true;
      db.collection(C.COLLECTION.USERS)
        .get()
        .then(querySnapshot => {
          const customerNames = ["All"];
          querySnapshot.forEach(doc => {
            const user = doc.data();
            user.id = doc.id;
            user.name = user.firstName.substring(0, 1) + ". " + user.lastName;
            // full name for search
            user.nameForSearch = user.firstName + " " + user.lastName;
            this.usersMap.set(doc.id, user);
            // prepare userList for appointment creation
            this.usersList.push(user);
            customerNames.push(user.name);
          });
          this.addToFilters(C.FILTER_TYPE.SELECTION, "Customer", "user", customerNames);
          this.asyncFlags[2] = true;
          if (this.asyncFlags[1] && this.asyncFlags[2]) {
            this.prepareSearchData();
            this.loadingDone();
          }
        })
        .catch(error => {
          this.loadingDone(error);
          console.log("Error getting users: ", error);
        });
    },
    userWatches: function(id) {
      //todo: set hasAppoitment in user, to filter users here
      this.dialogIsLoading = true;
      db.collection(C.COLLECTION.WATCHES)
        .where("userId", "==", id)
        .get()
        .then(querySnapshot => {
          this.watchesList.splice(0);
          querySnapshot.forEach(doc => {
            const watch = doc.data();
            watch.id = doc.id;
            const index = this.watchesList.push(watch) - 1;
            // fetching model name & updating it in list dynamically via this.$set() (to force UI to reload data)
            db.collection(C.COLLECTION.WATCH_CATALOG)
              .doc(watch.modelId)
              .get()
              .then(modelDoc => {
                watch.model = modelDoc.data().name;
                this.$set(this.watchesList, index, watch);
              });
          });
          this.dialogIsLoading = false;
        })
        .catch(error => {
          this.dialogIsLoading = false;
          this.loadingDone(error);
          console.log("Error getting users: ", error);
        });
    },
    filterQuery: function(filter) {
      //if (this.filters[filter]) return;
      this.isLoading = true;
      var ref = db.collection(C.COLLECTION.APPOINTMENTS);
      // all
      if (filter == 0) {
        this.changeActiveFilter(0);
      }
      // pending
      if (filter == 1) {
        ref = ref.where("status", "in", [0, 5]);
        this.changeActiveFilter(1);
      }
      // approved
      if (filter == 2) {
        ref = ref.where("status", ">=", 1).where("status", "<=", 2);
        this.changeActiveFilter(2);
      }
      // completed
      if (filter == 3) {
        ref = ref.where("status", "==", 3);
        this.changeActiveFilter(3);
      }
      // cancelled
      if (filter == 4) {
        ref = ref.where("status", "==", 4);
        this.changeActiveFilter(4);
      }
      ref
        .where("paid", "==", true)
        .get()
        .then(querySnapshot => {
          this.appointmentsList.splice(0);
          querySnapshot.forEach(doc => {
            this.addDocToAppointmentList(doc);
          });
          this.asyncFlags[0] = true;
          if (this.asyncFlags[1] && this.asyncFlags[2]) this.loadingDone();
        });
      // .catch((error) => {
      //   this.isLoading = false;
      //   this.showError = true;
      //   console.log("Error getting appointments: ", error);
      // });
    },
    changeActiveFilter(clicked) {
      this.$set(this.filters, this.activeFilter, false);
      this.$set(this.filters, clicked, true);
      this.activeFilter = clicked;
    },
    showCreateAppointmentDialog() {
      this.validationMsg = "";
      // open dialog
      this.$refs["create-appointment-modal"].show();
    },

    nextCreateStep(step, id) {
      if (step == 1) {
        this.userId = id;
        this.dialogIsLoading = true;
        this.userWatches(id);
      }
      if (step == 2) {
        this.watchId = id;
        this.watchesList.forEach(watch => {
          if (watch.id === id) {
            this.watchModleId = watch.modelId;
          }
        });
      }
      if (step == 3) this.serviceId = id;
      this.$set(this.steps, this.activeStep, false);
      this.$set(this.steps, step, true);
      this.activeStep = step;
    },
    previousStep() {
      if (this.activeStep == 0) {
        this.$refs["create-appointment-modal"].hide();
        return;
      }
      this.$set(this.steps, this.activeStep, false);
      this.$set(this.steps, this.activeStep - 1, true);
      this.activeStep = this.activeStep - 1;
    },
    createAppointment() {
      this.isLoading = true;
      db.collection(C.COLLECTION.APPOINTMENTS)
        .doc()
        .set({
          date: new Date(this.appointmentDate + "T00:00:00.000"),
          createdOn: serverTimestamp,
          modifiedOn: serverTimestamp,
          userId: this.userId,
          watchId: this.watchId,
          serviceId: this.serviceId,
          modelId: this.watchModleId,
          serviceName: this.servicesList.get(this.serviceId).name,
          servicePrice: this.servicesList.get(this.serviceId).price,
          status: 0,
          paid: true
        })
        .then(() => {
          this.$refs["create-appointment-modal"].hide();
          this.loadingDone();
          toast.success("New service appointment created.");
        })
        .catch(error => {
          toast.error("Couldn't create appointment. " + error.message);
        });
    },
    updateAppointment() {
      this.isSaving = true;
      db.collection(C.COLLECTION.APPOINTMENTS)
        .doc(this.appointmentId)
        .set(
          {
            modifiedOn: serverTimestamp,
            date: this.editData.date,
            status: 0
          },
          { merge: true }
        )
        .then(() => {
          this.$refs["edit-appointment-modal"].hide();
          this.isSaving = false;
          toast.success("Appointment updated.");
        })
        .catch(error => {
          this.isSaving = false;
          toast.error("Couldn't update appointment. " + error.message);
        });
    },
    updateAppointmentStatus(id, status) {
      this.isSaving = true;
      db.collection(C.COLLECTION.APPOINTMENTS)
        .doc(id)
        .set(
          {
            modifiedOn: serverTimestamp,
            status: status
          },
          { merge: true }
        )
        .then(() => {
          this.isSaving = false;
          this.filterQuery(this.activeFilter);
          toast.success("Appointment status changed.");
        })
        .catch(error => {
          this.isSaving = false;
          toast.error("Couldn't change appointment status. " + error.message);
        });
    }
  },
  mounted() {
    this.allAppointments();
    this.allServices();
    this.allUsers();
  }
};
</script>

<style scoped></style>
